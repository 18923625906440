import CanvasJSReact from '@canvasjs/react-charts';
import Card from 'react-bootstrap/Card';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Graph(props){

  let graphType = props.graphType;

  if (props.data){
    let options = {
      theme: "dark2",
      title: {
        text: props.title,
        fontSize: 20
      },
      axisY: {
        includeZero: false,
      },
      axisX: {
        interval: 1
      },
      data: [{
        type: graphType,
        yValueFormatString: "#,### Students",
        dataPoints: props.data
      }]
    } 

    if (graphType === "pie"){
      options = {
        theme: "dark2",
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: props.title,
          fontSize: 20
        },
        data: [{
          type: "pie",
          startAngle: 50,
          radius: props.radius,
          indexLabelFontSize: 14,
          indexLabel: "{label} - #percent%  ", 
          toolTipContent: "<b>{label}:</b> {y} (#percent%)",
          percentFormatString: "#0.0",    
          dataPoints: props.data.map((point, index) => ({
            ...point,
            color: getColor(index)  // Assign color to each column
          }))
        }]
      }
    }

    else if (graphType == "doughnut"){
      options = {
        theme: "dark2",
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: props.title,
          fontSize: 20
        },
        subtitles: [{
          text: props.subtitle,
          verticalAlign: "center",
          dockInsidePlotArea: true,
          fontSize: 30
        }],
        data: [{
          type: "doughnut", 
          radius: props.radius,
          startAngle: 270,
          innerRadius: "90%",
          indexLabelFontSize: 13,
          dataPoints: [
            { y: props.data,  color: "orange", toolTipContent: "<b>Enrolled</b>: {y} students" }, 
            { y: props.data1, color: "white", toolTipContent: "<b>Remaining</b>: {y} students" }
          ]
        }]
      };
    }

    else if (props.graphType === "doughnut2") {
      options = {
        // backgroundColor: "#F6F6F6",
        theme: "dark2",
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: props.title,
          // theme: "dark2",
          // // fontColor: "#951C8D",
          fontSize: 20,
          // fontFamily: "verdana",
          // fontWeight: "bold",
          margin: 50,
          itle: props.title1,
          interval: props.interval,
          labelFontSize: 12,
          titleFontSize: 12
        },
        legend: {
          cursor: "pointer",
          verticalAlign: "top",
        },
        data: [
          {
            type: "doughnut",
            radius: props.radius,
            startAngle: 270,
            innerRadius: "60%",
            indexLabelFontSize: 14,
            showInLegend: true,
            legendText: "{label}",
            dataPoints: props.data.map((point, index) => ({
              ...point,
              color: getColor(index),
            })),
          },
        ],
      };
    }
    
    else if (graphType === "multiCol"){
      options = {
        theme: "dark2",
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: props.title,
          fontSize: 20
        },	
        toolTip: {
          shared: true
        },
        axisX: {
          interval: 1
        },
        data: [{
          type: "column",
          legendText: props.colText1,
          showInLegend: true, 
          dataPoints:props.data,
          color: "#f07819"
        },
        {
          type: "column",	
          legendText: props.colText2,
          showInLegend: true,
          dataPoints:props.data1,
          color: "#ebe4d6"
        }]
      }
    }

    else if (graphType === "combo") {
      options = {
        // backgroundColor: "#F6F6F6",
        theme: "dark2",
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: props.title,
          // // fontColor: "#951C8D",
          fontSize: 20,
          // fontFamily: "verdana",
          // // fontWeight: "bold",
          margin: 30,
        },
        axisX: {
          title: "Enrolment Count (Monthly)",
          fontFamily: "verdana",
          interval: 100,
        },
        axisY: {
          title: "Cumulative Enrolment Count",
          fontFamily: "verdana",
          interval: 100,
        },
        axisX: {
          interval: 1,
        },
        data: [
          {
            type: "line",
            legendText: props.colText1,
            showInLegend: true,
            dataPoints: props.linedata1,
            color: "#B79FFF",
            toolTipContent: "<b>{month} {legendText}</b>: {original}",
            axisYType: "secondary",
          },
          {
            type: "line",
            legendText: props.colText2,
            showInLegend: true,
            dataPoints: props.linedata2,
            color: "rgb(0, 255, 0)",
            toolTipContent: "<b>{month} {legendText}</b>: {original}",
            axisYType: "secondary",
          },
        ],
      };
    }

    else if (graphType === "barChart") {
      options = {
        theme: "dark2",
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: props.title,
          fontSize: 20
        },
        toolTip: {
          shared: true
        },
        axisY: {
          title: props.title1,
          interval: props.interval,
          labelFontSize: 14,
          titleFontSize: 16
        },
        axisX: {
          title: props.title2,
          interval: 1,
          labelFormatter: function (e) {
            return e.label;
          },
          labelFontSize: 14,
          titleFontSize: 16
        },
        data: [{
          type: "bar",
          showInLegend: false,
          indexLabelFontWeight: "bold",
          dataPoints: props.data.map(dataPoint => ({
            ...dataPoint,
            indexLabel: String(dataPoint.y), 
            indexLabelPlacement: "outside", 
            indexLabelFontSize: 17
          }))
        }]
      }
    }

    else if (graphType === "barChart2") {
      options = {
        theme: "dark2",
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: props.title,
          fontSize: 18
        },
        toolTip: {
          shared: true
        },
        axisY: {
          title: props.title1,
          // interval: props.interval,
          labelFontSize: 14,
          titleFontSize: 16,
          includeZero: true
        },
        axisX: {
          title: "Year",
          interval: 1,
          intervalType: "year"
        },
        data: [
          {
            type: "column",
            name: "Applicants",
            showInLegend: true,
            dataPoints: [
                { label: props.label2, y: props.data1.applicants }, // Replace with actual data
                { label: props.label, y:  props.data.applicants  }  // Replace with actual data
            ]
          },
          {
            type: "column",
            name: "Enrolled",
            showInLegend: true,
            dataPoints: [
                { label: props.label2, y: props.data1.students }, // Replace with actual data
                { label: props.label, y:  props.data.students  }  // Replace with actual data
            ]
          }
        ]
      }
    }

    const containerProps = {
        width: "100%",
        height: "100%",
    }
                
    return (
      <Card className="dark" style={{ height: props.height, width: props.width}}>
        <Card.Body>
          <CanvasJSChart options = {options} containerProps = {containerProps} />
        </Card.Body>
      </Card>
    );
  }
}

function getColor(index) {
  // const colors = ["#6ca0dc", "#ffb6c1", "#9BBB58", "#24BEAA"]; // Add more colors if needed
  // const colors = ["#b2427a", "#974994", "#665abb", "#5a94bb", "#479c82", "#a4a653", "#a66853"]
  const colors = ["#FBF5DD", "#A6CDC6", "#E5D0AC", "#DDA853", "#BFBBA9", "#727D73", "#AAB99A", "#D99D81"]
  return colors[index % colors.length]; // Cycle through colors
}

export default Graph;


