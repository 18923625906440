import {React, useState, useEffect, useRef} from 'react';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/App.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import NavMenu from '../components/NavMenu';
import Graph from '../components/Graph';
import DigitalClock from '../components/DigitalClock';
import InfoCard from '../components/InfoCard';
import PerformanceCard from '../components/PerformanceCard';
// import Map from '../components/Map';
import CoursesStartingSoonTable from "../components/CoursesStartingSoonTable"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';

function DashboardHome() {

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  useEffect(() => {
    const savedSessionData = Cookies.get('sessionData');
    if (savedSessionData) {
      const data = JSON.parse(savedSessionData);
      if (data['time']) {
        setIntervalValue(data['time']);
      }
      if (data['isViewAllowed'] === 1) {
        navigateRef.current('/');
      }
    } else {
      navigateRef.current('/login');
    }
  }, []);

  const callbacksRef = useRef(() => callbacks());
  useEffect(() => {
    callbacksRef.current();
  }, []);


  const [interval, setIntervalValue] = useState([300000]);    // to refresh and reload (initial 5 min)
  useEffect(() => {
    const intervalId = setInterval(async () => {
        callbacksRef.current();
    }, interval);
    return () => {
        clearInterval(intervalId);                           // Clear the interval when the component unmounts
    };
  }, [interval]);   


  let currYear = new Date().getFullYear();
  let prevYear = currYear - 1;
  let nextYear = currYear + 1;

  let currDate = new Date(); // Get current date
  let nextYearFirstDay = new Date(currDate.getFullYear() + 1, 0, 1); // Jan 1st of next year
  let diffInMilliseconds = nextYearFirstDay - currDate;
  let remainingDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  function dateFromNumToMonth(num) {
    if (num === 1) return "Jan";
    if (num === 2) return "Feb";
    if (num === 3) return "Mar";
    if (num === 4) return "Apr";
    if (num === 5) return "May";
    if (num === 6) return "Jun";
    if (num === 7) return "Jul";
    if (num === 8) return "Aug";
    if (num === 9) return "Sep";
    if (num === 10) return "Oct";
    if (num === 11) return "Nov";
    if (num === 12) return "Dec";
  }

  const [stuEnrollCountCur, setStuEnrollCountCur] = useState(['--']);
  const [stuApplyCountCur, setStuApplyCountCur] = useState(['--']);
  const [stuEnrollCountPrev, setStuEnrollCountPrev] = useState(['--']);
  const [stuApplyCountPrev, setStuApplyCountPrev] = useState(['--']);
  const [recruitersCount, setRecruitersCount] = useState(['--']);
  const [agentsCount, setAgentsCount] = useState(['--']);

  const [enrollVsMonth, setEnrolVsMonth] = useState([]);
  const [applyVsMonth, setApplyVsMonth] = useState([]);
  const [enrollVsMonthPrev, setEnrolVsMonthPrev] = useState([]);
  const [applyVsMonthPrev, setApplyVsMonthPrev] = useState([]);

  const [topEnroll, setTopEnrol] = useState([]);  
  const [topApplication, setTopApplication] = useState([]); 
  const [enrollCourse, setEnrollCourse] = useState([]); 

  const [studentAgeGroups, setStudentAgeGroups] = useState([]);
  const [studentCounts, setStudentCounts] = useState([]);
  const [studentCountries, setStudentCountries] = useState([]);
  const [studentGenders, setStudentGenders] = useState([]);
  const [
    EnrolmentLineAcrossMonthsLastYear,
    setEnrolmentLineAcrossMonthsLastYear,
  ] = useState([]);
  const [
    EnrolmentLineAcrossMonthsThisYear,
    setEnrolmentLineAcrossMonthsThisYear,
  ] = useState([]);

  // const [applyVsCountry, setApplyVsCountry] = useState([]);
  // const [enrollCountry, setEnrollCountry] = useState([]);
  // const [applyVsCountryPrev, setApplyVsCountryPrev] = useState([]);  

  async function callbacks(){
     getEnrollData(prevYear,"","");
     getEnrollData(currYear,"","");
     getApplyData(prevYear,"","");
     getApplyData(currYear,"","");
     getAgentsData(currYear,'13');
     getTopEnrollment();
     getTopApplication();
     getEnrollCourse(currYear, "");
     getEnrolledStudentsByAge();
     getEnrolledStudentsByGender();
     getEnrolledStudentsByCountries();
     getEnrolmentBySBUCodeData();
     getApplicantStatusData();
     getEnrolmentTrendAcrossMonthsData();
     getCoursesStartingSoonData();
     getEnrolledStudentDemographicsByCountryData();
     getEnrolmentToApplicantRatioYearlyData();
     // getEnrollCountryData(prevYear,"","");
  }

  // getEnrolmentToApplicantRatioYearly
  const [EnrolmentToApplicantRatioYearly, setEnrolmentToApplicantRatioYearly] = useState([]);
  const [EnrolmentToApplicantRatioPrevYear, setEnrolmentToApplicantRatioPrevYear] = useState([]);
  async function getEnrolmentToApplicantRatioYearlyData() {
    const url =
      process.env.REACT_APP_API + "/getEnrolmentToApplicantRatioYearly";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setEnrolmentToApplicantRatioYearly(
        data.length === 0 ? 0 : data[0]
      );
      setEnrolmentToApplicantRatioPrevYear(
        data.length === 0 ? 0 : data[1]
      );
      
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  // getEnrolledStudentDemographicsByCountry
  const [
    EnrolledStudentDemographicsByCountry,
    setEnrolledStudentDemographicsByCountry,
  ] = useState([]);
  async function getEnrolledStudentDemographicsByCountryData() {
    const url =
      process.env.REACT_APP_API +
      "/getEnrolledStudentDemographicsByCountry";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleEnrolledStudentDemographicsByCountryData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  function handleEnrolledStudentDemographicsByCountryData(data) {
    const countryCounts = data.reduce(
      (acc, student) => {
        if (student.country_name === "Singapore") {
          acc.singapore += 1;
        } else if (student.country_name === "China") {
          acc.china += 1;
        } else {
          acc.others += 1;
        }
        return acc;
      },
      { singapore: 0, china: 0, others: 0 }
    );

    const tempData = [
      {
        label: "Singapore",
        y: countryCounts.singapore,
      },
      {
        label: "China",
        y: countryCounts.china,
      },
      {
        label: "Others",
        y: countryCounts.others,
      },
    ];

    setEnrolledStudentDemographicsByCountry(tempData);
  }

  // get CoursesStartingSoon
  const [CoursesStartingSoon, setCoursesStartingSoon] = useState([]);
  async function getCoursesStartingSoonData() {
    const url = process.env.REACT_APP_API + "/getCoursesStartingSoon";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      setCoursesStartingSoon(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolmentTrendAcrossMonthsData() {
    const url =
      process.env.REACT_APP_API + "/getEnrolmentTrendAcrossMonths";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleEnrolmentTrendAcrossMonthsData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }
  function handleEnrolmentTrendAcrossMonthsData(data) {
    const prevYearData = data.filter(
      (enrolment) => enrolment.enrolled_year === prevYear
    );
    const currYearData = data.filter(
      (enrolment) => enrolment.enrolled_year === currYear
    );
    let count2 = 0;
    let count4 = 0;

    let cumulativeCount1 = 0;
    const lineData1 = prevYearData.map((enrolment) => {
      cumulativeCount1 += enrolment.enrolment_count;
      return {
        y: cumulativeCount1,
        original: enrolment.enrolment_count,
        label: dateFromNumToMonth((count4 += 1)),
      };
    });

    let cumulativeCount2 = 0;
    const lineData2 = currYearData.map((enrolment) => {
      cumulativeCount2 += enrolment.enrolment_count;
      return {
        y: cumulativeCount2,
        original: enrolment.enrolment_count,
        label: dateFromNumToMonth((count2 += 1)),
      };
    });

    setEnrolmentLineAcrossMonthsLastYear(lineData1);
    setEnrolmentLineAcrossMonthsThisYear(lineData2);
  }

   // getApplicantStatus
   const [ApplicantStatus, setApplicantStatus] = useState([]);
   async function getApplicantStatusData() {
     const url = process.env.REACT_APP_API + "/getApplicantStatus";
     const configs = {
       method: "GET",
       headers: {
         Authorization: "Bearer " + localStorage.getItem("token"),
         "Content-Type": "application/json",
       },
     };
     try {
       const response = await axios.get(url, configs);
       const data = response.data.key;
       handleApplicantStatusData(data);
     } catch (error) {
       if (
         !error.response.data.success &&
         error.response.status === 401 &&
         error.response.statusText === "Unauthorized"
       ) {
         Cookies.remove("sessionData");
         localStorage.removeItem("token");
         navigate("/");
         return;
       }
       console.error("Error fetching data:", error);
     }
   }
 
   function handleApplicantStatusData(data) {
     const transformedData = data.map((status) => ({
       y: status["Num of Applicants"],
       label: status["Status"],
     }));
     setApplicantStatus(transformedData);
   }
  
  // get EnrolmentBySBUCode
  const [enrolmentBySBUCode, setEnrolmentBySBUCode] = useState([]);
  async function getEnrolmentBySBUCodeData() {
    const url = process.env.REACT_APP_API + "/getEnrolmentBySBUCode";
    const configs = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleEnrolmentBySBUCodeData(data);
    } catch (error) {
      if (
        !error.response.data.success &&
        error.response.status === 401 &&
        error.response.statusText === "Unauthorized"
      ) {
        Cookies.remove("sessionData");
        localStorage.removeItem("token");
        navigate("/");
        return;
      }
      console.error("Error fetching data:", error);
    }
  }
  function handleEnrolmentBySBUCodeData(data) {
    var arr = [];
    data.forEach((x) => {
      arr.push({y: x.sbuCtr, label: x.sbu_description})
    })
    setEnrolmentBySBUCode(arr);
  }

  async function getTopEnrollment(){
    const url = process.env.REACT_APP_API + `/getNewEnroled`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data){
        setTopEnrol(data)
      }
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }
  
  async function getTopApplication(){
    const url = process.env.REACT_APP_API + `/getNewApplication`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data){
        setTopApplication(data)
      }
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrollData(selectedYear, selectedRegion, selectedCourseType) {
    const url = process.env.REACT_APP_API + `/getNewPerformance`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        type: 'enrollment',
        recTeam: selectedRegion,
        courseTypeId: selectedCourseType
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleEnrolVsMonth(data, selectedYear);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getApplyData(selectedYear, selectedRegion, selectedCourseType) {
    const url = process.env.REACT_APP_API + `/getNewPerformance`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        type: 'application',
        recTeam: selectedRegion,
        courseTypeId: selectedCourseType
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleApplyVsMonth(data, selectedYear);
      // handleApplyVsCountry(data, selectedYear);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }
  
  async function getAgentsData(selectedYear, status) {
    const url = process.env.REACT_APP_API + `/getAgentsByStatus`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        status: status
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      if (data){
        setAgentsCount(data.length);
      }
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrollCourse(selectedYear, selectedCourseType) {
    const url = process.env.REACT_APP_API + `/getCourseEnrollmentList`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {
        year: selectedYear,
        courseTypeId: selectedCourseType
      }
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleCourseEnroll(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolledStudentsByAge() {
    const url = process.env.REACT_APP_API + `/getEnrolledStudents`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      // handleStudentGenders(data);
      handleStudentAgeGroups(data);
      // handleStudentCountries(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolledStudentsByGender() {
    const url = process.env.REACT_APP_API + `/getEnrolledStudentsByGender`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleStudentGenders(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  async function getEnrolledStudentsByCountries() {
    const url = process.env.REACT_APP_API + `/getEnrolledStudentsByCountries`;
    const configs = {
      headers:{
        'Authorization': 'Bearer '+localStorage.getItem("token"),
        'Content-Type': 'application/json',   
      },
      params: {}
    };
    try {
      const response = await axios.get(url, configs);
      const data = response.data.key;
      handleStudentCountries(data);
    } catch (error) {
      if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
        Cookies.remove('sessionData');
        localStorage.removeItem('token')
        navigate('/login')
        return;
      }
      console.error("Error fetching data:", error);
    }
  }

  // async function getEnrollCountryData(selectedYear, selectedRegion, selectedCourseType) {
  //   const url = process.env.REACT_APP_API + `/getNewPerformance`;
  //   const configs = {
  //     headers:{
  //       'Authorization': 'Bearer '+localStorage.getItem("token"),
  //       'Content-Type': 'application/json',   
  //     },
  //     params: {
  //       year: selectedYear,
  //       type: 'enrollment',
  //       recTeam: selectedRegion,
  //       courseTypeId: selectedCourseType
  //     }
  //   };
  //   try {
  //     const response = await axios.get(url, configs);
  //     const data = response.data.key;
  //     // handleApplyVsMonth(data, selectedYear);
  //     // handleApplyVsCountry(data, selectedYear, configs.params.type);
  //   } catch (error) {
  //     if(!error.response.data.success && error.response.status == 401 && error.response.statusText == "Unauthorized") {
  //       Cookies.remove('sessionData');
  //       localStorage.removeItem('token')
  //       navigate('/login')
  //       return;
  //     }
  //     console.error("Error fetching data:", error);
  //   }
  // }


  function handleEnrolVsMonth(data, year) {
    let count = 0;
    let stuCount = 0;
    const tempData = months.reduce((acc, month) => {
      if (data){
        const monthCount = data.reduce((total, element) => total + element[month.toLowerCase() + '_count'], 0);
        acc.push({ x: count, y: monthCount, label: month });
        count++;
        stuCount += monthCount;
        return acc;
      }
      else{
        return null;
      }
    }, []);
    if (year === currYear && data){
      setRecruitersCount(data.length);
      setEnrolVsMonth(tempData);
      setStuEnrollCountCur(stuCount);
    }
    else if (year === prevYear && data){
      setEnrolVsMonthPrev(tempData);
      setStuEnrollCountPrev(stuCount);
    }
  }

  function handleApplyVsMonth(data, year) {
    let count = 0;
    let stuCount = 0;
    const tempData = months.reduce((acc, month) => {
      if (data){
        const monthCount = data.reduce((total, element) => total + element[month.toLowerCase() + '_count'], 0);
        acc.push({ x: count, y: monthCount, label: month });
        count++;
        stuCount += monthCount;
        return acc;
      }
      else{
        return null;
      }
    }, []);
    if (year === currYear && data){
      setApplyVsMonth(tempData);
      setStuApplyCountCur(stuCount);
    }
    else if (year === prevYear && data){
      setApplyVsMonthPrev(tempData);
      setStuApplyCountPrev(stuCount);
    } 
  }

  function handleCourseEnroll(data){
    const tempData = data.map(course => ({
      label: course.course_code,
      y: course.total  
    }));
    setEnrollCourse(tempData);
  }

  function handleStudentGenders(data){
    const genderCounts = data.reduce((acc, student) => {
      if (student.gender === 'Male') {
        acc.male += 1;
      } else if (student.gender === 'Female') {
        acc.female += 1;
      }
      return acc;
    }, { male: 0, female: 0 });
  
    const tempData = [
      {
        label: 'Male',
        y: genderCounts.male
      },
      {
        label: 'Female',
        y: genderCounts.female
      }
    ];
  
    setStudentGenders(tempData);
  }

  function handleStudentAgeGroups(data){
    const ageGroupCounts = data.reduce((acc, student) => {
      if (student.age >= 5 && student.age <= 19) {
        acc.ageGroup1 += 1;
      } else if (student.age > 19 && student.age <= 25) {
        acc.ageGroup2 += 1;
      } else if (student.age > 25 && student.age <= 30) {
        acc.ageGroup3 += 1;
      } else if (student.age > 30) {
        acc.ageGroup4 += 1;
      }
      return acc;
    }, { ageGroup1: 0, ageGroup2: 0, ageGroup3: 0, ageGroup4: 0 });

    const tempData = [
      {
        label: '< 20',
        y: ageGroupCounts.ageGroup1
      },
      {
        label: '20-25',
        y: ageGroupCounts.ageGroup2
      },
      {
        label: '26-30',
        y: ageGroupCounts.ageGroup3
      },
      {
        label: '30+',
        y: ageGroupCounts.ageGroup4
      }
    ];
    
    setStudentAgeGroups(tempData);
  }

  function handleStudentCountries(data){
    const countryStudentCount = data.reduce((acc, student) => {
      const { country_name } = student;
      const country = acc.find(c => c.country_name === country_name);
      if (!country) {
        acc.push({
          country_name: country_name,
          student_count: 1,
          coordinates: [student.longitude, student.latitude]
        });
      } else {
        country.student_count += 1;
      }    
      return acc;
    }, []);
    setStudentCounts(countryStudentCount);


    const countries = data.reduce((acc, studentCountry) => {
      const { country_name } = studentCountry;
      const country = acc.find(c => c.country_name === country_name);
      if (!country) {
        acc.push({
          country_name: country_name,
          coordinates: [studentCountry.longitude, studentCountry.latitude]
        });
      }   
      return acc;
    }, []);
    setStudentCountries(countries);
  }

  
  // function handleApplyVsCountry(data, year, type) {
  //   let tempData = [];
  //   let chinaCount = 0;
  //   let singaporeCount = 0;
  //   let othersCount = 0;
  //   if(data){
  //     data.forEach((element, index) => {
  //       if (element['country_name'] === "China"){
  //         months.forEach((month, index1) => {
  //           chinaCount += (element[month.toLowerCase() + '_count']);
  //         })
  //       }
  //       else if(element['country_name'] === "Singapore"){
  //         months.forEach((month, index1) => {
  //           singaporeCount += (element[month.toLowerCase() + '_count']);
  //         })
  //       }
  //       else {
  //         months.forEach((month, index1) => {
  //           othersCount += (element[month.toLowerCase() + '_count']);
  //         })
  //       }
  //     });
  //     tempData.push({ y: chinaCount, label: "China" });
  //     tempData.push({ y: singaporeCount, label: "Singapore" });
  //     tempData.push({ y: othersCount, label: "Others" });
  //     if(type === 'enrollment') {
  //       setEnrollCountry(tempData)
  //     } else {
  //       if (year === currYear){
  //         setApplyVsCountry(tempData);
  //       }
  //       else{
  //         setApplyVsCountryPrev(tempData);
  //       }
  //     }
  //   }
  // }


  const [isSec1Visible, setSec1Visible] = useState(true); 
  const [isSec2Visible, setSec2Visible] = useState(false);
  const [isSec3Visible, setSec3Visible] = useState(false);
  const [isSec4Visible, setSec4Visible] = useState(false);
  const [slideVisible, setSlideVisible] = useState();
  const [slideExit, setSlideExit] = useState();

  function changeSection(direction = null){
    if(direction == 'l'){
      setSlideVisible('slide-visible-l');
      setSlideExit('slide-exit-l');

      if(isSec1Visible){
        setSec1Visible(false); setSec2Visible(false); setSec3Visible(false); setSec4Visible(true);
      }
      else if(isSec2Visible){ 
        setSec1Visible(true); setSec2Visible(false); setSec3Visible(false); setSec4Visible(false);
      }
      else if(isSec3Visible){
        setSec1Visible(false); setSec2Visible(true); setSec3Visible(false); setSec4Visible(false);
      }
      else {
        setSec1Visible(false); setSec2Visible(false); setSec3Visible(true); setSec4Visible(false);
      }
    }
    else{
      setSlideVisible('slide-visible-r');
      setSlideExit('slide-exit-r');

      if(isSec1Visible){
        setSec1Visible(false); setSec2Visible(true); setSec3Visible(false); setSec4Visible(false);
      }
      else if(isSec2Visible){ 
        setSec1Visible(false); setSec2Visible(false); setSec3Visible(true); setSec4Visible(false);
      }
      else if(isSec3Visible){
        setSec1Visible(false); setSec2Visible(false); setSec3Visible(false); setSec4Visible(true);
      }
      else {
        setSec1Visible(true); setSec2Visible(false); setSec3Visible(false); setSec4Visible(false);
      }
    }
  }

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'ArrowRight') {
        event.preventDefault();
        changeSection('r');
      }
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        changeSection('l');
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [changeSection]);


  return (
    <div >
        <NavMenu />
        <Container fluid>
          <div className="d-flex flex-row">
            <div className="col-3" style={{minHeight:'42vh'}}>
              <DigitalClock />
              <InfoCard 
                stuEnrollCur={stuEnrollCountCur} 
                stuApplyCur={stuApplyCountCur} 
                stuEnrollPrev={stuEnrollCountPrev} 
                stuApplyPrev={stuApplyCountPrev} 
                agents={agentsCount} 
                recruiters={recruitersCount} 
                currYear = {currYear}
                prevYear = {prevYear}
                nextYear = {nextYear}
                remainingDays = {remainingDays}/>
            </div>
            
            {/* <div className="col-1 d-flex align-items-center" style={{width: '3%'}}>
              <button className="btn btn-secondary" style={{transform: 'rotate(90deg)'}} onClick={changeSection('l')}> 
                <FontAwesomeIcon color={"white"} icon={faSortDown} style={{width:'2rem'}}/>
              </button>
            </div> */}

            {/* Section 1 */}
            {isSec1Visible && (
              <div className={`col ${isSec1Visible ? slideVisible : slideExit}`} style={{minHeight:'42rem'}}>
                <div style={{height:'50%'}}> 
                  <Graph data={enrollVsMonthPrev} data1={enrollVsMonth} title={"Student Enrolment " + prevYear + " vs " + currYear } 
                    colText1={`${prevYear}`} colText2={`${currYear}`} graphType={"multiCol"} height="95%" width="95%"/>  
                </div>
                <div style={{height:'50%'}}>
                  <Graph data={applyVsMonthPrev} data1={applyVsMonth} title={"Student Application " + prevYear + " vs " + currYear} 
                    colText1={`${prevYear}`} colText2={`${currYear}`} graphType={"multiCol"} height="95%" width="95%"/>                
                </div>
              </div>
            )}
            {isSec1Visible && (
              <div className={`col ${isSec1Visible ? slideVisible : slideExit}`} style={{minHeight:'42rem'}}>
                <div style={{height:'50%'}}> 
                  <PerformanceCard enrollmentList={topEnroll} title={`Latest 5 Enrolments`} type="enrollment" height="95%" width="95%"/>
                </div>
                <div style={{height:'50%'}}>
                  <PerformanceCard enrollmentList={topApplication} title={`Latest 5 Applications`} type="application" height="95%" width="95%"/> 
                </div>
              </div>
            )}


            {/* Section 2 */}
            {isSec2Visible && (
              <div className={`col ${isSec2Visible ? slideVisible : slideExit}`} style={{minHeight:'42rem'}}>
                <div style={{height:'100%'}}> 
                  <Graph data={enrollCourse} title={"Course Enrolment - " + currYear } 
                  title1={"Student Count"} title2={"Courses"} graphType={"barChart"} interval={2} height="100%" width="95%"/>  
                </div>
              </div>
            )}
            {isSec2Visible && (
              <div className={`col ${isSec2Visible ? slideVisible : slideExit}`} style={{minHeight:'40rem'}}>
                <div className='d-flex flex-row justify-content-between' style={{height:'50%', width:"95%"}}> 
                  <Graph data={studentGenders} title={"Enrolment By Gender Groups - " + currYear} graphType={"pie"} height="95%" width="48%" radius="75"/>
                  <Graph title={"Yearly Comparison of Enrolled Students and Applicants"} title2="No. of Student/Applicants" 
                  data={EnrolmentToApplicantRatioYearly} data1={EnrolmentToApplicantRatioPrevYear} label={currYear} label2={currYear-1}
                  graphType={"barChart2"} height="95%" width="48%" radius="75"/> 
                  {/* <Graph data={EnrolmentToApplicantRatioYearly} data1={EnrolmentToApplicantRatioPrevYear} label={currYear+" Enrolled"} label1={currYear-1+" Applicants"} 
                    title={currYear+" Application to Enrolment Conversion Rate"} subtitle={(EnrolmentToApplicantRatioYearly).toFixed(1) + ' %'} graphType={"doughnut"} height="95%" width="48%" radius="75"/> */}
                  {/* <Graph data={stuEnrollCountCur} data1={stuApplyCountCur-stuEnrollCountCur} label={"Enrolled"} label1={"Remaining"} 
                    title={"Application to Enrolment Conversion Rate - " + currYear} subtitle={((stuEnrollCountCur*100)/stuApplyCountCur).toFixed(1) + ' %'} graphType={"doughnut"} height="95%" width="48%" radius="75"/> */}
                </div>
                <div className='d-flex flex-row justify-content-between' style={{height:'50%', width:"95%", gap: "20px"}}> 
                  <Graph data={studentAgeGroups} title={"Enrolment By Age Groups - " + currYear} 
                  title1="Student Count" title2="Age Groups" graphType={"barChart"} interval={5} height="100%" width="50%"/>
                  <Graph data={EnrolmentToApplicantRatioYearly.applicant_to_enrolment_percentage} data1={EnrolmentToApplicantRatioPrevYear.applicant_to_enrolment_percentage} label={currYear+" Enrolled"} label1={currYear-1+" Applicants"} 
                    title={currYear+" Application to Enrolment Conversion Rate"} subtitle={(EnrolmentToApplicantRatioYearly.applicant_to_enrolment_percentage).toFixed(1) + ' %'} graphType={"doughnut"} width="50%" radius="75"/>
                </div>
              </div>
            )}
            

            {/* Section 3 */}
            {isSec3Visible && (
              <div className={`col ${isSec3Visible ? slideVisible : slideExit} d-flex justify-content-center align-items-center`} style={{minHeight:'42rem'}}>
                {/* <Map data1={studentCounts} data2={studentCountries}/> */}
                <div className={"container text-center h-100"}>
                  <div className={"row row-cols-2 h-100"}>
                    <div className={"col"}>
                      <Graph
                        data={EnrolmentLineAcrossMonthsLastYear}
                        linedata1={EnrolmentLineAcrossMonthsLastYear}
                        linedata2={EnrolmentLineAcrossMonthsThisYear}
                        title={"Cumulative Enrolment " + prevYear + " vs " + currYear}
                        colText1={`${prevYear}`}
                        colText2={`${currYear}`}
                        lineText1={`${prevYear}`}
                        lineText2={`${currYear}`}
                        graphType={"combo"}
                        height="95%" width="95%"
                      />
                    </div>
                    <div className={"col"}>
                      <Graph
                        data={ApplicantStatus}
                        title={"Applicant Status for the last 12 months"}
                        graphType={"pie"} height="95%" width="95%"
                      />
                    </div>
                    <div className={"col"}>
                      <Graph
                        data={enrolmentBySBUCode}
                        title={"Enrolment by SBU"}
                        graphType={"doughnut2"} height="95%" width="95%"
                      />
                    </div>
                    <div className={"col"}>
                    <Graph
                        data={EnrolledStudentDemographicsByCountry}
                        title={"Enrolled Students by Country"}
                        graphType="pie"
                        height="95%" width="95%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Section 4 */}
            {isSec4Visible && (
              <div className={`col ${isSec4Visible ? slideVisible : slideExit} d-flex justify-content-center align-items-center`} style={{minHeight:'42rem'}}>
                <div className={'d-flex flex-column'} style={{padding:'15px 20px', borderRadius:'8px', background:'rgba(40, 44, 52, 0.8)', width:'100%', height:'100%'}}>
                  <div className='d-flex justify-content-between align-itemss-center'>
                    <span style={{color:'white', fontWeight:'600', fontSize:'22px'}}>Courses Starting Soon</span>
                    <i className='text-warning'>Course continues to be shown for 21 days after SOC.</i>
                  </div>
                  <CoursesStartingSoonTable tableData={CoursesStartingSoon} />
                </div>
              </div>
            )}

            <div className="col-1 d-flex align-items-center" style={{width: '3%'}}>
              <button className="btn btn-secondary" style={{transform: 'rotate(-90deg)'}} onClick ={changeSection}> 
                <FontAwesomeIcon color={"white"} icon={faSortDown} style={{width:'2rem'}}/>
              </button>
            </div>


            {/* ======= GRAPH FOR ENROLL BY COUNTRY
            <Graph data={enrollCountry} title={"Student Enrolment by Country " + currYear} graphType={"doughnut"}/> 
            */}

            {/* ========== STUDENT APPLICATION 2023 vs 2024
            <div className="col" style={{height:'80vh !important'}}>
              <div style={{height:'50%'}}>
                <Graph data={applyVsCountry} title={"Student Application by Country " + currYear} graphType={"doughnut"}/>
              </div>
              <div style={{height:'50%', display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                <Graph data={applyVsCountryPrev} title={"Student Application by Country " + prevYear} graphType={"doughnut"}/>
              </div>
            </div> */}
            
          </div>
        </Container>
    </div>
  );
}

export default DashboardHome;