import React from "react";
// import "./Table.css";
import '../../src/App.css';

function CoursesStartingSoonTable(props) {
  let data = props.tableData;

  let num = 0;

  let date;
  let dateString;

  return (
    <div className="tableWrap-2">
      {/* <table className="table table-striped table-dark table-responsive maxWidthNone mt-2"  style={{height:'fit-content', border:"2px solid"}}> */}
      <table style={{width: '100%', tableLayout: 'auto', borderCollapse: 'collapse', height: '100%'}}>
        <thead>
          <tr>
            <th className="table-header" style={{width:'5%'}}>No.</th>
            <th className="table-header" style={{width:'12%'}}>Course Code</th>
            <th className="table-header">Course Name</th>
            <th className="table-header" style={{width:'10%'}}>Commencement Date</th>
            <th className="table-header" style={{width:'10%'}}>No. of Pending Applications</th>
            <th className="table-header" style={{width:'10%'}}>No. of Projected Progression</th>
            <th className="table-header" style={{width:'10%'}}>No. of Enrolments</th>
            <th
              className="table-header"
              style={{ width: "10%" }}
            >
              Total
            </th>
          </tr>
        </thead>

        <tbody>
          {data.map((element, index) => {
            // row count (iterate through each row)
            num++;

            date = new Date(element["Commencement Date"]);
            const day = date.toLocaleDateString("en-US", { day: "2-digit" });
            const month = date.toLocaleDateString("en-US", {
              month: "2-digit",
            });
            const year = date.toLocaleDateString("en-US", { year: "numeric" });
            dateString = `${day}-${month}-${year}`;

            // columns (insert data into each column)
            return (
              <tr
                key={index}
                 style={
                  element["total_enrolments"] >= 5
                    ? { backgroundColor: "#DDD5F3 !important" }
                    : { backgroundColor: "red !important" }
                } 
               className={element["total_enrolments"] >= 5 ? "highlight-row" : ""}
              >
                <td className="table-data">{num}</td>
                <td className="table-data">{element["Course Code"]}</td>
                <td className="table-data">{element["Course Name"]}</td>
                <td className="table-data">{dateString}</td>
                <td className="table-data">
                  {element["Number of New Applicants"]}
                </td>
                <td className="table-data">
                  {element["Number of Projected Progression"]}
                </td>
                <td className="table-data">{element["total_enrolments"]}</td>
                <td className="table-data">{element["total_all"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CoursesStartingSoonTable;
